import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { WhatWeDoPageTemplate } from "./what-we-do"

const WhatWeDoPage = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  return (
    <Layout>
      <Seo title={frontmatter.title} description={frontmatter.description} />
      <WhatWeDoPageTemplate {...frontmatter} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $langKey: String!) {
    markdownRemark(fields: { slug: { eq: $slug }, langKey: { eq: $langKey } }) {
      frontmatter {
        description
        title
        servicesIntroMD
        productsIntroMD
        services {
          description
          imageAlt
          title
          buttonText
          buttonLink
          image {
            childImageSharp {
              gatsbyImageData(height: 300, placeholder: BLURRED)
            }
          }
        }
        products {
          description
          imageAlt
          title
          buttonText
          buttonLink
          image {
            childImageSharp {
              gatsbyImageData(height: 300, placeholder: BLURRED)
            }
          }
        }
        testimonials {
          author
          authorInfo
          content
        }
      }
    }
  }
`

export default WhatWeDoPage
