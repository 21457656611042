import FancyUnderline from "../../components/fancy-underline"
import PropTypes from "prop-types"
import LeftRightSections from "../../components/left-right-sections"
import React from "react"
import TestimonialsSlider from "../../components/testimonials-slider"
import { HTMLContent } from "../../components/content"
import MarkdownIntroSection from "../../components/markdown-intro"

const TitleSection = ({ title }) => {
  return (
    <section className="container mx-auto mt-4 px-5 text-center">
      <div className="inline-block relative pb-2">
        <p className="text-4xl font-extralight">{title}</p>
        <FancyUnderline />
      </div>
    </section>
  )
}

TitleSection.defaultProps = {
  title: "",
}

TitleSection.propTypes = {
  title: PropTypes.string,
}

const ServicesSection = ({ services }) => {
  return !services ? null : (
    <section className="flex flex-col space-y-4 container mx-auto px-5 mt-12">
      {services.map((service, index) => (
        <LeftRightSections
          key={service.title}
          buttonLink={service.buttonLink}
          buttonText={service.buttonText}
          description={service.description}
          image={service.image}
          title={service.title}
          imageAlt={service.imageAlt}
          odd={index % 2 !== 0}
        />
      ))}
    </section>
  )
}

ServicesSection.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      buttonLink: PropTypes.string,
      buttonText: PropTypes.string,
      imageAlt: PropTypes.string,
      image: PropTypes.object,
    })
  ),
}

const Divider = () => {
  return (
    <div className="container mx-auto px-5">
      <hr className="border border-wpt-grey my-8" />
    </div>
  )
}

const ProductsSection = ({ products }) => {
  return !products ? null : (
    <section className="flex flex-col space-y-4 container mx-auto px-5 mt-12 mb-4">
      {products.map((product, index) => (
        <LeftRightSections
          key={product.title}
          buttonLink={product.buttonLink}
          buttonText={product.buttonText}
          description={product.description}
          image={product.image}
          title={product.title}
          imageAlt={product.imageAlt}
          odd={index % 2 !== 0}
        />
      ))}
    </section>
  )
}

ProductsSection.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      buttonLink: PropTypes.string,
      buttonText: PropTypes.string,
      imageAlt: PropTypes.string,
      image: PropTypes.object,
    })
  ),
}
const TestimonialSection = ({ testimonials }) => {
  return !testimonials ? null : (
    <section className="mt-5 bg-wpt-primary">
      <TestimonialsSlider testimonials={testimonials} />
    </section>
  )
}

TestimonialSection.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      author: PropTypes.string,
      authorInfo: PropTypes.string,
    })
  ),
}

export const WhatWeDoPageTemplate = ({
  title,
  servicesIntroMD,
  services,
  productsIntroMD,
  products,
  testimonials,
  preview,
}) => {
  return (
    <>
      <TitleSection title={title} />
      <MarkdownIntroSection
        html={servicesIntroMD}
        contentComponent={!preview && HTMLContent}
      />
      <ServicesSection services={services} />
      {!!productsIntroMD && <Divider />}
      <MarkdownIntroSection
        html={productsIntroMD}
        contentComponent={!preview && HTMLContent}
      />
      <ProductsSection products={products} />
      <TestimonialSection testimonials={testimonials} />
    </>
  )
}
